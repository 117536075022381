<template>
  <footer class="relative bg-primary pt-8 pb-6">
    <div
      class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
      style="transform: translateZ(0);"
    >
      <svg
        class="absolute bottom-0 overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          class="text-primary fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
    <div class="container w-full mx-auto">
      <div class="flex text-center items-center md:justify-between justify-center flex-wrap flex-col md:flex-row">

          <div class="flex-1 text-xs text-blueGray-500">Copyright © {{ date }} by Ana Garzon & Wilson Tayar</div>
          <div class="flex-grow">♥</div>
          <div class="flex-1 text-xs text-blueGray-500">Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      date: new Date().getFullYear(),
    };
  },
};
</script>
