<template>
  <div class="bg-white relative flex flex-col min-w-0 break-words w-full mb-6 rounded">
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th
              class="w-4/6 px-6 bg-amber-50 text-black border-blueGray-100 align-middle rounded border border-solid py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
            >Item</th>
            <th
              class="w-1/6 px-6 bg-amber-50 text-black border-blueGray-100 align-middle border border-solid py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"
            >Quantidade</th>
            <th
              class="w-1/6 px-6 bg-amber-50 text-black border-blueGray-100 align-middle border border-solid py-3 text-md border-l-0 border-r-0 whitespace-nowrap font-semibold text-right"
            >Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in basket" v-bind:key="item.gift.id">
            <td
              class="w-4/6 font-bold text-black border-t-0 px-6 align-middle border-l-0 border-r-0 text-md whitespace-nowrap p-4 text-left flex items-center"
            >{{ item.gift.name }}</td>
            <td
              class="w-1/6 border-t-0 px-6 align-middle border-l-0 border-r-0 text-md whitespace-nowrap p-4"
            >
              <div class="flex">
                <span
                  @click="removeGiftFromBasket(item.gift)"
                  class="button-change-quantity cursor-pointer text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-secondary uppercase"
                >
                  <i class="fas fa-minus"></i>
                </span>
                <span class="text-center flex-1">{{ item.quantity }}</span>
                <span
                  @click="addGiftToBasket(item.gift)"
                  class="button-change-quantity cursor-pointer text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-secondary uppercase"
                >
                  <i class="fas fa-plus"></i>
                </span>
              </div>
            </td>
            <td
              class="w-1/6 border-t-0 px-6 align-middle border-l-0 border-r-0 text-md whitespace-nowrap p-4 text-right"
            >R$ {{item.quantity * item.gift.price}},00</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import { mapActions } from 'vuex'

export default {
  name: 'basket-table',
  props: {
    basket: {
      type: Array
    }
  },
  methods: {
    ...mapActions(['addGiftToBasket', 'removeGiftFromBasket'])
  }
};
</script>
<style lang="scss">
.button-change-quantity {
  width: 24px;
  padding-left: 7px;
  padding-right: 7px;
}
</style>