<template>
  <nav
    class="top-0 fixed z-40 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-primary shadow"
  >
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
      <div
        class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start"
      >
        <router-link to="/">
          <a
            class="text-black text-lg font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap"
            href="#home"
          >Ana & Will</a>
        </router-link>
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
          type="button"
          v-on:click="setNavbarOpen"
        >
          <i class="fas fa-bars"></i>
        </button>
      </div>
      <div
        class="lg:flex flex-grow items-center"
        :class="[navbarOpen ? 'block' : 'hidden']"
        id="example-navbar-warning"
      >
        <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
          <li class="nav-item">
            <a
              class="px-3 py-2 text-black flex items-center text-md leading-snug hover:opacity-75"
              href="/"
            >Home</a>
          </li>
          <li class="nav-item">
            <a
              class="px-3 py-2 text-black flex items-center text-md leading-snug hover:opacity-75"
              href="#nossa-historia"
            >Nossa História</a>
          </li>
          <li class="nav-item">
            <a
              class="px-3 py-2 text-black flex items-center text-md leading-snug hover:opacity-75"
              href="#presentes"
            >Presentes</a>
          </li>
          <li class="nav-item">
            <button
              :class="{'bg-secondary': modelValue == 0, 'bg-emerald-600': modelValue > 0 }"
              class="relative text-white font-bold text-sm px-4 py-2 ml-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button" @click="openBasket"
            >
              <i class="fas fa-gift leading-lg opacity-75 mr-1"></i> Cesta
              <span
                v-if="modelValue > 0"
                class="animate-bounce absolute bottom-basket-number text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-secondary uppercase last:mr-0 mr-1"
              >{{ modelValue }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      navbarOpen: false,
    };
  },
  props: {
    modelValue: {
      type: Number
    }
  },
  methods: {
    setNavbarOpen: function () {
      this.navbarOpen = !this.navbarOpen;
    },
    openBasket: function () {
      this.$emit('openBasket')
    }
  }
};
</script>
<style lang="scss">
.bottom-basket-number {
  bottom: -8px;
  right: -8px;
}
</style>