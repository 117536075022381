<template>
  <div
    class="modal border-0 rounded-lg shadow-lg relative flex flex-col md:w-full bg-primary outline-none focus:outline-none"
  >
    <!--header-->
    <div class="flex items-start justify-between p-5 rounded-t">
      <h3 class="text-3xl font-semibold text-secondary">Cesta de Presentes</h3>
    </div>
    <!--body-->
    <div class="relative px-6">
      <div v-if="basket.length > 0">
        <p
            class="mb-6 text-xl leading-tight text-black"
          >Revise seus presentes!</p>
          <p class="mt-2 mb-4 text-black lg:w-5/6 w-full">Na próxima página, entregue seus presentes através de um Pix e envie uma mensagem para os noivos.</p>
        <div class="flex flex-wrap">
          <div class="w-full">
            <basket-table :basket="basket"></basket-table>
          </div>
        </div>
        <p class="text-right font-bold">Total: R$ {{ basketTotal }},00</p>
        
      </div>
      <div v-else>
        <p>Parece que você ainda não adicionou nenhum presente em sua cesta.</p>
        <p>Basta clicar no botão "Adicionar" na lista de presentes para prosseguir!</p>
      </div>
    </div>
    <!--footer-->
    <div class="flex items-center justify-end p-6 rounded-b">
      <button
        class="bg-transparent border border-solid border-secondary text-secondary font-bold rounded text-black text-lg background-transparent px-6 py-3 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        v-on:click="close()"
      >Voltar</button>
      <span v-if="basket.length > 0" class="flex-1"></span>
      <button
        class="bg-secondary text-white active:bg-emerald-600 font-bold text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        v-on:click="next()"
        v-if="basket.length > 0"
      >Prosseguir</button>
    </div>
  </div>
</template>

<script>
import BasketTable from './BasketTable.vue'

import { mapGetters } from 'vuex'

export default {
  components: { BasketTable },
  name: "basket-modal-step-one",
  computed: {
    ...mapGetters(['basket', 'basketTotal'])
  },
  methods: {
    close() {
      this.$emit('close')
    },
    next() {
      this.$emit('next')
    }
  }
}
</script>