<template>
  <div
    class="flex flex-col md:flex-row overflow-hidden bg-white rounded-lg shadow-lg mt-8 w-100 mx-2"
  >
    <div class="h-64 w-auto md:w-1/2">
      <link rel="preload" :href="gift.picture" as="image" type="image/jpg" />
      <img
        class="inset-0 h-full w-full object-cover object-center"
        :src="gift.picture"
      />
    </div>

    <div class="w-full py-4 px-6 text-black flex flex-col justify-between">
      <h5 class="font-bold text-3xl">{{ gift.name }}</h5>
      <p class="mt-4 mb-8"> {{ gift.description }} </p>
      <div class="flex">
        <p
          class="flex-1 lg:text-2xl text-xl text-gray-700 uppercase tracking-wide font-semibold mt-2 pb-2"
        >R$ {{ gift.price }},00</p>
        <button
          class="text-white font-bold text-md lg:text-lg lg:px-8 lg:py-3 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
          type="button"
          :class="{ 'bg-emerald-600': isSelected, 'bg-secondary': !isSelected }"
          @click="toggleSelected"
        >
        <span v-if="!isSelected">
            <i class="fas fa-gift leading-lg opacity-75 mr-1"></i> 
            Adicionar
        </span>
        <span v-else>
            <i class="fas fa-check leading-lg opacity-75 mr-1"></i>
            Adicionado!
        </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: "gift-card",
  props: {
    gift: {
      type: Object
    }
  },
  computed: {
      isSelected() {
        return this.hasGiftInBasket(this.gift)
      },
      ...mapGetters(['hasGiftInBasket'])
  },
  methods: {
      toggleSelected() {

          if (!this.isSelected) {
              this.$emit('addGiftToBasket', this.gift)
          } else {
              this.$emit('removeGiftFromBasket', this.gift)
          }
          
      }
  }
}
</script>