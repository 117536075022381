<template>
  <div>
    <index-navbar v-model="basket.length" v-on:openBasket="openBasket" />
    <div class="relative pt-8 pb-8 flex content-center items-center justify-center h-500-px">
      <div
        class="absolute top-0 w-full h-full bg-center bg-cover"
        v-bind:style="{ backgroundImage: 'url(' + header + ')' }"
      ></div>
      <div class="container relative mx-auto">
        <div class="items-center flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
            <h1 class="text-black font-semibold text-5xl">Ana & Will</h1>
          </div>
        </div>
      </div>
      <div
        class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon class="text-primary fill-current" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
    </div>
    <section
      id="nossa-historia"
      class="header bg-primary relative pt-16 pb-16 items-center flex max-h-full"
    >
      <div class="container mx-auto items-center flex flex-wrap">
        <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="pt-8 sm:pt-1">
            <h2 class="font-semibold text-4xl text-secondary">Nossa História</h2>
            <p
              class="mt-4 text-md leading-relaxed text-black"
            >585km de distância separaram a maior parte dos momentos da vida de Wilson e Ana. Ela de Belo Horizonte, ele de São Paulo, os dois foram fazendo escolhas que os direcionaram a trabalhar, coincidentemente, no mesmo prédio em 2018. Quando se conheceram, já era claro que eram muito compatíveis e complementares. Em dezembro do mesmo ano os dois se tornaram o casal que desejaram ser desde o princípio ♥️</p>
            <p
              class="mt-4 text-md leading-relaxed text-black"
            >Daí em diante todas as distâncias foram se reduzindo cada vez mais: passaram a morar na mesma rua, depois na mesma casa e, quando veio a pandemia, passaram a conviver 24h por dia, todos os dias da semana. Tudo isso só reforçou a certeza de que se davam bem, de que superariam tudo juntos e que queriam permanecer assim.</p>
            <p
              class="mt-4 text-md leading-relaxed text-black"
            >Agora, os dois querem continuar trilhando caminhos e contando quilômetros, cada vez mais juntos e pelo mundo afora!</p>
            <div class="mt-12"></div>
          </div>
        </div>
        <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="sm:pt-1">
            <photo-gallery :pictures="pictures"></photo-gallery>
            <div class="lg:mt-12"></div>
          </div>
        </div>
      </div>
    </section>
    <section id="presentes" class="header bg-white relative pt-16 items-center max-h-full pb-32">
      <div
        class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon class="text-white fill-current" points="2560 0 2560 100 0 100" />
        </svg>
      </div>
      <div class="container mx-auto px-4">
        <h2 class="font-semibold text-4xl text-secondary w-full pb-8">Presentes</h2>
        <p
          class="text-md font-semibold leading-relaxed text-black"
        >A aventura de viajar o mundo tem lugar certo para começar: Itália!</p>
        <p
          class="mt-4 mb-8 text-md leading-relaxed text-black lg:w-3/6 w-full"
        >O primeiro destino do casal será esse país mágico, onde eles desejam passar os primeiros meses de casados como nômades digitais. Dê seu carinho em forma de presentes simbólicos que vão ajudar os dois a começar a jornada!</p>
        <div class="container w-100 lg:w-4/5 mx-auto flex flex-col">
          <gift-card
            v-for="(gift) in gifts"
            v-bind:key="gift.id"
            :gift="gift"
            v-on:addGiftToBasket="addGiftToBasket"
            v-on:removeGiftFromBasket="removeGiftFromBasket"
          ></gift-card>
        </div>
      </div>
    </section>
    <footer-component />
    <button
      v-if="basket.length > 0"
      :class="{'bg-secondary': basket.length == 0, 'bg-emerald-600': basket.length > 0 }"
      class="md:invisible fixed bottom-0 z-40 w-full text-white font-bold text-xl px-8 py-4 shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
      type="button"
      @click="openBasket"
    >
      <i class="fas fa-gift leading-lg opacity-75 mr-1"></i> Cesta
      <span
        v-if="basket.length > 0"
        class="animate-bounce absolute basket-button-bottom text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-white bg-secondary uppercase"
      >{{ basket.length }}</span>
    </button>
    <basket-modal v-model="isBasketOpen" :basket="basket" v-on:close="closeBasket"></basket-modal>
  </div>
</template>
<script>
import IndexNavbar from "@/components/Navbars/IndexNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import GiftCard from "@/components/Wedding/GiftCard.vue";
import PhotoGallery from "@/components/Wedding/PhotoGallery.vue";
import BasketModal from '../components/Wedding/BasketModal.vue';

import header from "@/assets/img/header/header.jpg";

import gallery1 from "@/assets/img/gallery/1.jpg";
import gallery2 from "@/assets/img/gallery/2.jpg";
import gallery3 from "@/assets/img/gallery/3.jpg";
import gallery4 from "@/assets/img/gallery/4.jpg";
import gallery5 from "@/assets/img/gallery/5.jpg";
import gallery6 from "@/assets/img/gallery/6.jpg";
import gallery7 from "@/assets/img/gallery/7.jpg";
import gallery8 from "@/assets/img/gallery/8.jpg";

import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    IndexNavbar,
    FooterComponent,
    GiftCard,
    PhotoGallery,
    BasketModal
  },
  data () {
    return {
      header,
      pictures: [
        gallery1,
        gallery2,
        gallery3,
        gallery4,
        gallery5,
        gallery6,
        gallery7,
        gallery8],
      isBasketOpen: false,
      gifts: [
        {
          id: 'd1ed1d73-1c55-4d63-bc44-76c6f49dfc19',
          name: 'Café da Manhã em Roma',
          description: 'As “caffetterie” estão por toda a parte na Itália, principalmente em Roma. O café é uma das bebidas mais consumidas na Itália e, claro, é um dos melhores lugares do mundo para tomar um belo café da manhã com um cornetto.',
          price: 50,
          picture: "https://source.unsplash.com/pDC7vQrW-EM/800x600"
        },
        {
          id: '2d401cb5-82c0-443d-b152-fe80e5765854',
          name: 'Pizza em Nápoles',
          description: 'Não poderia faltar la vera pizza napoletana nesta lista! Se a Itália é o berço da pizza, Nápoles é a terra natal, sendo considerada o lugar com uma das melhores pizzas do mundo.',
          price: 100,
          picture: "https://source.unsplash.com/FZTwpjRUr38/800x600"
        },
        {
          id: 'fd3b57b0-45a6-4ec3-9927-4311b5a460bc',
          name: 'Queijos e Vinhos na Toscana',
          description: 'Não dá para não sonhar com uma degustação de queijos e vinhos em um fazenda cercadas por vinhedos na Toscana! Esse lugar é rico em sabores e aromas únicos: vinhos mundialmente famosos, azeites, massas, tartufo, mel, chocolates…',
          price: 150,
          picture: "https://source.unsplash.com/MMOXoxE_Gj0/800x600"
        },
        {
          id: '4701db6f-c336-47ad-b129-40f8aaa06c05',
          name: 'Excursão no Coliseu',
          description: 'O Coliseu é o principal cartão postal de Roma e um dos pontos turísticos mais visitados do mundo. Muito mais do que um símbolo, é praticamente uma entidade romana.',
          price: 250,
          picture: "https://source.unsplash.com/75XHJzEIeUc/800x600"
        },
        {
          id: '26a45f26-c670-4b70-b34d-140862550d0d',
          name: 'Passeio de Gôndola em Veneza',
          description: 'Ah, Veneza! É a cidade dos amantes, além de ser a famosa cidade sobre as águas! E a sua maior atração são os passeios de gôndola (esses barquinhos charmosos).',
          price: 350,
          picture: "https://source.unsplash.com/9v9JDw-bGqw/800x600"
        },
        {
          id: '8a0a0304-2a95-4bc4-aed9-330927473ec3',
          name: 'Fim de Semana em Capri',
          description: 'A ilha reúne paisagens fantásticas com falésias e grutas, além de, claro, ter uma ótima cena gastronômica.',
          price: 500,
          picture: "https://source.unsplash.com/0kVnoA6Thgs/800x600"
        },
        {
          id: 'cbeb1abf-bdcb-4709-9682-6f9c1ba4d0a6',
          name: 'Viagem de Trem pela Europa',
          description: 'Existe melhor maneira de viajar do que de trem? É mais barato e mais ecológico que o avião e mais rápido e confortável que o ônibus, além de ser uma forma emocionante de ver as paisagens do Velho Continente!',
          price: 1000,
          picture: "https://source.unsplash.com/PM5a_R83-YQ/800x600"
        }
      ]
    }
  },
  computed: { ...mapGetters(['basket']) },
  methods: {
    closeBasket () {
      this.isBasketOpen = false
    },
    openBasket () {
      this.isBasketOpen = true
    },
    ...mapActions(['addGiftToBasket', 'removeGiftFromBasket'])
  }
};
</script>
<style lang="scss">
.basket-button-bottom {
  right: 7.15rem;
  top: 1.3rem;
}
</style>