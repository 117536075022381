<template>
  <div
    class="modal border-0 rounded-lg shadow-lg relative flex flex-col md:w-full bg-primary outline-none focus:outline-none"
  >
    <!--header-->
    <div class="flex items-start justify-between p-5 rounded-t">
      <h3 class="text-3xl font-semibold text-secondary">Depósito por Pix</h3>
    </div>
    <!--body-->
    <div class="relative px-6">
      <p
        class="mb-6 text-xl leading-tight text-black"
      >Entregue seu presente ao casal através do depósito por Pix!</p>
      <p class="mt-2 mb-4 text-black lg:w-5/6 w-full">Na sequência, clique em prosseguir para enviar uma mensagem ao casal.</p>
      <div class="md:flex">
        <div class="mx-auto w-full md:w-4/6 flex">
          <img class="object-cover" v-if="pix" :src="pix.qrbase64" alt="QR Code" />
          <div v-else class="rounded bg-orange-200 h-72 animate-pulse flex-1"></div>
        </div>
        <div class="md:pl-4 w-full flex flex-col">
          <small class="block mt-6 md:mt-1 ml-1">Pix copia e cola</small>
          <div
            class="cursor-pointer mt-1 relative flex w-full flex-wrap items-stretch mb-3"
            @click="copyPixCode"
          >
            <span
              class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
            >
              <i class="fas fa-copy"></i>
            </span>
            <input
              id="pix_code"
              type="text"
              readonly="readonly"
              :value="pix ? pix.qrstring : ''"
              placeholder="Carregando..."
              :class="{'animate-pulse': !pix }"
              class="cursor-pointer px-3 py-3 placeholder-gray-500 text-black relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:ring focus:outline-none w-full ease-linear transition-all duration-150 pl-10"
            />
          </div>
          <p
            class="mt-2 text-black"
          >Você pode scanear o QR Code ou copiar o código acima para realizar o procedimento.</p>
          <p class="flex-1"></p>
          <small class="block mt-4 text-gray-500">
            <i class="fas fa-lock"></i> Nós não coletamos os seus dados e não processamos o pagamento. Tudo será feito através do seu banco, garantindo a sua segurança.
          </small>
        </div>
      </div>
    </div>
    <!--footer-->
    <div class="flex items-center justify-end p-6 rounded-b">
      <button
        class="bg-transparent border border-solid border-secondary text-secondary font-bold rounded text-black text-lg background-transparent px-6 py-3 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        v-on:click="close()"
      >Voltar</button>
      <span v-if="basket.length > 0" class="flex-1"></span>
      <button
        class="bg-secondary text-white active:bg-emerald-600 font-bold text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        v-on:click="next()"
        :disabled="!pix"
        v-if="basket.length > 0"
      >Prosseguir</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getPixData } from '@/api/pix.api'

export default {
  name: "basket-modal-step-two",
  data () {
    return {
      pix: null
    }
  },
  computed: {
    ...mapGetters(['basket', 'basketTotal'])
  },
  mounted () {
    this.generatePixData()
  },
  methods: {
    async generatePixData () {
      this.pix = await getPixData(this.basketTotal)
      this.keepPixDataInSession(this.pix)
    },
    copyPixCode () {
      document.querySelector("#pix_code").select();
      document.execCommand("copy");
    },
    close: function () {
      this.$emit('close')
    },
    next () {
      this.$emit('next')
    },
    ...mapActions(['keepPixDataInSession'])
  }
}
</script>