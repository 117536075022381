import { createStore } from 'vuex'
import { db, Timestamp } from '@/api/firestore.api'

export default createStore({
  state: {
    basket: [],
    sendingMessage: false,
    messageSent: false,
    pixData: null
  },
  getters: {
    basket: (state) => state.basket,
    hasGiftInBasket: (state) => (gift) => state.basket.find((i) => i.gift.id == gift.id),
    basketTotal: (state) => state.basket.reduce((val, i) => (i.gift.price * i.quantity) + val, 0),
    sendingMessage: (state) => state.sendingMessage,
    messageSent: (state) => state.messageSent
  },
  mutations: {
    addGiftToBasket(state, gift) {
      let itemAlreadyAdded = state.basket.find((i) => i.gift.id === gift.id)

      if (itemAlreadyAdded) {
        itemAlreadyAdded.quantity++
        return
      }

      state.basket.push({ quantity: 1, gift })
    },
    removeGiftFromBasket(state, gift) {
      let itemAlreadyAdded = state.basket.find((i) => i.gift.id === gift.id)

      if (itemAlreadyAdded) {
        if (itemAlreadyAdded.quantity >= 2) {
          itemAlreadyAdded.quantity--
          return
        }

        let itemIndex = state.basket.findIndex((i) => i.gift.id === gift.id)
        state.basket.splice(itemIndex, 1);
      }
    },
    resetBasket(state) {
      state.basket = []
      state.sendingMessage = false
      state.messageSent = false
      state.pixData = null
    },
    sendingMessage(state) {
      state.sendingMessage = true
    },
    messageSent(state) {
      state.sendingMessage = false
      state.messageSent = true
    },
    keepPixDataInSession(state, pixData) {
      state.pixData = pixData
    }
  },
  actions: {
    addGiftToBasket: ({ commit }, gift) => {
      commit('addGiftToBasket', gift)
    },
    removeGiftFromBasket: ({commit}, gift) => {
      commit('removeGiftFromBasket', gift)
    },
    resetBasket: ({commit}) => {
      commit('resetBasket')
    },
    keepPixDataInSession: ({commit}, pixData) => {
      commit('keepPixDataInSession', pixData)
    },
    sendMessage: async ({commit, state, getters }, { name, message }) => {
      commit('sendingMessage')
      await db.collection('messages').add({
        name: name,
        message: message,
        basket: state.basket,
        basketTotal: getters.basketTotal,
        pix: state.pixData,
        date: Timestamp.fromDate(new Date())
      })
      commit('messageSent')
    }
  },
  modules: {
  }
})
