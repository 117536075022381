<template>
  <div>
    <div
      v-if="modelValue"
      class="modal-container overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none animated fadeIn faster"
    >
      <div class="relative w-auto my-6 mx-auto max-w-3xl">
        <!--content-->
        <basket-modal-step-one v-if="step == 1" v-on:close="close" v-on:next="next"></basket-modal-step-one>
        <basket-modal-step-two v-if="step == 2" v-on:close="close" v-on:next="next"></basket-modal-step-two>
        <basket-modal-step-three v-if="step == 3" v-on:next="next"></basket-modal-step-three>
      </div>
    </div>
    <div v-if="modelValue" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import BasketModalStepOne from './BasketModalStepOne.vue'
import BasketModalStepTwo from './BasketModalStepTwo.vue'
import BasketModalStepThree from './BasketModalStepThree.vue'

import { mapGetters } from 'vuex'

export default {
  components: { BasketModalStepOne, BasketModalStepTwo, BasketModalStepThree },
  name: "basket-modal",
  props: {
    modelValue: {
      type: Boolean
    },
    basket: {
      type: Array
    }
  },
  data () {
    return {
      step: 1
    }
  },
  computed: {
    ...mapGetters(['basketTotal'])
  },
  methods: {
    close () {
      document.querySelector('.modal').scrollIntoView()
      this.step--

      if (this.step < 1) {
        this.step = 1
        const modal = document.querySelector('.modal-container');
        modal.classList.remove('fadeIn');
        modal.classList.add('fadeOut');
        setTimeout(() => {
          this.$emit('close')
        }, 500);
      }
    },
    next () {
      document.querySelector('.modal').scrollIntoView()
      this.step++

      if (this.step > 3) {
        this.step = 1
        const modal = document.querySelector('.modal-container');
        modal.classList.remove('fadeIn');
        modal.classList.add('fadeOut');
        setTimeout(() => {
          this.$emit('close')
        }, 500);
      }
    }
  }
}
</script>
<style lang="scss">
.modal {
  @media (min-width: 1024px) {
    width: 770px;
  }
  // animation: modal-in .5s;
  // transition: opacity 1s ease-in-out;
}

// @keyframes modal-in {
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
</style>