import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/analytics';

var firebaseConfig = {
  apiKey: "AIzaSyAHTvQ37IakAbFvAbwXoMXlW8pkdZsKv5U",
  authDomain: "wedding-experience-planner.firebaseapp.com",
  projectId: "wedding-experience-planner",
  storageBucket: "wedding-experience-planner.appspot.com",
  messagingSenderId: "196134720952",
  appId: "1:196134720952:web:c423be93e93d9f21c56b4d",
  measurementId: "G-HME9VT4YLX"
};

export const db = firebase.initializeApp(firebaseConfig).firestore()
firebase.analytics();

const { Timestamp, FieldValue } = firebase.firestore

export { Timestamp, FieldValue }
