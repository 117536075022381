<template>
  <ul class="relative h-64 md:h-96 w-5/6 pt-8 mx-auto gallery">
    <li v-for="(pic, index) in pictures" v-bind:key="index">
      <link rel="preload" :href="pic" as="image" type="image/jpg" />
      <img
        loading="lazy"
        :src="pic"
        alt="..."
        class="absolute shadow-lg rounded max-w-full h-auto align-middle border-none fade-in-image"
      />
    </li>
  </ul>
</template>
<script>
export default {
  name: 'photo-gallery',
  props: {
    pictures: {
      type: Array
    }
  }
}
</script>
<style lang="scss">
.gallery {
  img {
    opacity: 0;
    z-index: 0;
    animation: imageAnimation 40s linear infinite 0s;
  }

  li:nth-child(2) img {
    animation-delay: 5s;
  }
  li:nth-child(3) img {
    animation-delay: 10s;
  }
  li:nth-child(4) img {
    animation-delay: 15s;
  }
  li:nth-child(5) img {
    animation-delay: 20s;
  }
  li:nth-child(6) img {
    animation-delay: 25s;
  }
  li:nth-child(7) img {
    animation-delay: 30s;
  }
  li:nth-child(8) img {
    animation-delay: 35s;
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }
  8% {
    opacity: 1;
    animation-timing-function: ease-out;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
</style>