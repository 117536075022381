<template>
  <div
    class="modal border-0 rounded-lg shadow-lg relative flex flex-col md:w-full bg-primary outline-none focus:outline-none"
  >
    <!--header-->
    <div class="flex items-start justify-between p-5 rounded-t">
      <h3 class="text-3xl font-semibold text-secondary">Muito Obrigada!</h3>
    </div>
    <!--body-->
    <div class="relative px-6">
      <p
        class="text-base italic leading-relaxed mt-0 mb-0 text-black"
      >Seu presente significa imensamente pra nós e vai nos deixar mais próximos do nosso sonho. Obrigada por fazer parte das memórias que estamos prontos para construir e por todo o carinho de sempre. Esperamos sua visita na nossa casinha na Itália!</p>
      <footer class="block text-black text-right">- Grande beijo, Ana e Will.</footer>
      <hr class="my-6" />
      <p
            class="text-black text-xl"
          >Se você está aqui é porque com certeza fez parte dessa história!</p>
      <div class="mt-2 flex flex-col md:flex-row mx-auto">
        <div class="w-auto md:w-3/6">
          
          <p
            class="text-black mt-4"
          >Ana e Will só têm a agradecer por estar com eles nesse momento tão único!</p>
          <p class="text-black mt-4">
            Para deixar as memórias ainda mais gostosas, que tal deixar uma mensagem de carinho para o casal?
          </p>
        </div>
        <div class="mt-3 w-auto md:w-5/6 md:pl-4">
          <small class="block mt-6 md:mt-1 ml-1">Seu nome</small>
          <div class="mt-1 relative flex w-full flex-wrap items-stretch mb-3">
            <span
              class="z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
            >
              <i class="fas fa-user"></i>
            </span>
            <input
              type="text"
              v-model="name"
              :disabled="sendingMessage"
              placeholder="Quem está presenteando o casal?"
              class="px-3 py-3 placeholder-gray-500 text-black relative bg-white bg-white rounded text-sm border-0 shadow outline-none focus:ring focus:outline-none w-full ease-linear transition-all duration-150 pl-10"
            />
          </div>
          <small class="block mt-6 md:mt-1 ml-1">Sua mensagem</small>
          <div class="mt-1 relative flex w-full flex-wrap items-stretch mb-3">
            <textarea
            type="text"
            v-model="message"
            :disabled="sendingMessage"
                class="border-0 px-3 py-3 placeholder-gray-500 text-BLACK bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                rows="4"
              placeholder="Digite uma mensagem"
            />
          </div>
          <small class="block mt-4 text-gray-500">
            <i class="fas fa-lock"></i> Somente o casal terá acesso a sua mensagem.
          </small>
        </div>
      </div>
    </div>
    <!--footer-->
    <div class="flex items-center justify-end p-6 rounded-b">
      <button
        :class="{'bg-secondary': !messageSent, 'opacity-75': sendingMessage, 'bg-emerald-600': messageSent}"
        class=" text-white active:bg-emerald-600 font-bold text-lg px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
        type="button"
        :disabled="sendingMessage"
        v-on:click="next()"
      >
        <span v-if="sendingMessage"><i class="animate-spin fas fa-spinner opacity-75 mr-1"></i> Enviando...</span>
        <span v-else-if="!messageSent"><i class="fas fa-envelope opacity-75 mr-1"></i> Enviar</span>
        <span v-else><i class="animate-bounce fas fa-heart mr-1"></i> Obrigada!</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: "basket-modal-step-three",
  data() {
    return {
      name: '',
      message: ''
    }
  },
  computed: {
    ...mapGetters(['basket', 'basketTotal', 'sendingMessage', 'messageSent'])
  },
  methods: {
    async next() {
      await this.sendMessage({ 
        name: this.name, 
        message: this.message
      })
      
      setTimeout(() => {
        this.resetBasket()
        this.$emit('next')
      }, 3000)
    },
    ...mapActions(['resetBasket', 'sendMessage'])
  }
}
</script>